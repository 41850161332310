/**
 * 代理详情
 */
import * as Service from '@/service/order'
import EmptyList from '@/components/common/empty-list/index.vue'
import { mapState } from 'vuex'
import { SHILISHOU_REBATE_MAP } from '@/constants/rebate'

export default {
  name: 'order-review',
  components: { EmptyList },
  methods: {
    // 去到订单的详情页面
    toOrderDetail (item) {
      this.$router.push({
        name: 'order-detail',
        params: {
          id: item.id + ''//接受类型需要string
        }
      });
    },
    // 渠详情页面
    toDetail (row) {
      this.$router.push({
        path: 'order-review-detail',
        // 传太多怕炸-
        query: {
          row: JSON.stringify({
            createdAt: row.createdAt,
            orderProductPOS: row.orderProductPOS.filter((ele) => { return { pName: ele.pName, quantity: ele.quantity } }),
            userLevel: row.userLevel,
            userName: row.userName,
            id: row.id,
            code: row.code,
            userHeadImg: row.userHeadImg,
            state: row.state
          })
        }
      });
    },

    // 初始化,不改变tab的
    async init () {
      this.showList = [];
      this.offset = 0;
      this.finished = false;
      this.loading = false
      this.getorderSuperList();
    },
    // 切换顶部tab的
    changeTab (item) {
      this.tab = item;
      this.init();

    },
    // 获取列表
    async getorderSuperList () {
      const { userId, limit, offset, tab, brandId } = this;
      const params = {
        userId: userId,
        state: encodeURI(tab.value),
        offset,
        brandId,
        limit
      }
      const rows = await Service.orderSuperList(params);
      offset === 0 ? this.showList = rows : this.showList.push(...rows);
      this.loading = false;
      // 如果返回的长度为<limit，则说明是加载完毕了，如果不是，则说明可以继续加载
      if (rows.length < limit) {
        this.finished = true;
        // 如果返回的长度是0并且当前的下标页面时0，则说明一条数据都没有
      }
      else {
        this.finished = false;
      }
    },
    // 下拉加载的函数
    onLoad (e) {
      this.offset += this.limit;
      this.getorderSuperList();
    },

    showAudit (item) {
      const { ISSLS } = this
      if (item.state === 3 && (!ISSLS)) {
        return true
      }

      if (ISSLS && item.state === 3) {
        if (item.userLevel === SHILISHOU_REBATE_MAP.tgz.levelText || item.userLevel === SHILISHOU_REBATE_MAP.bjvip.levelText) {
          return true
        }
      }

      return false
    },
  },

  data () {
    const ISSLS = process.env.VUE_APP_PROJECT_NAME === 'shilishou';//是否实力瘦，实力瘦会影响部分文字显示
    const typeList = [
      {
        name: ISSLS ? '公司待确定' : '待确定',
        value: "0,2,3",
        active: require("./../../../assets/images/order/selectd_unaudit.png"),
        unactive: require("./../../../assets/images/order/unselectd_unaudit.png"),
      },
      {
        name: ISSLS ? '公司已确认' : '已确认',
        value: "1,9",
        active: require("./../../../assets/images/order/selectd_audit.png"),
        unactive: require("./../../../assets/images/order/unselectd_audit.png"),
      },
    ];
    return {
      ISSLS,
      showList: [],
      finished: false,
      offset: 0,//页数，0开始
      limit: 10,//每页的调数
      loading: false,
      typeList,
      tab: typeList[0],//当前操作的tab
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId,
      userId: state => state.user.user.id // 我的ID
    })
  },

  watch: {
    '$route': {
      handler (newRoute, oldRoute) {
        this.init()
      }
    },
  },

  mounted () {
    this.init()
  }
}
